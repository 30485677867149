import { redirect, useNavigate } from "@remix-run/react";
import { useEffect } from "react";
import LoadingPage from "~/components/LoadingPage";
import { hasExpressedConsent, useUser } from "~/lib/hooks";
import { hasToken } from "~/lib/request";
import { getNavigateLink } from "~/lib/utils";

export const clientLoader = async () => {
  if (!hasExpressedConsent()) {
    return redirect("/consent");
  }

  if (!(await hasToken())) {
    return redirect("/onboarding/type");
  }

  return null;
};

clientLoader.hydrate = false;

export default function Index() {
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (user.data) {
      navigate(getNavigateLink(user.data.type, user.data.onboarding_status));
    }
  }, [user.data, navigate]);

  return <LoadingPage />;
}
